import { APP_ROUTES } from "./apiRoutes";
import axiosInstance from "./axiosInstance";

const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "local"
    ? process.env.REACT_APP_IMPARTUS_BASE_URL
    : "";

export const getDataForURI = async (uri) => {
  try {
    let res = await axiosInstance.get(
      `${BASE_URL}/${APP_ROUTES.GET_DATA_FOR_URI}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const handleDefaultLogin = async (username, password) => {
  try {
    let res = await axiosInstance.post(
      `${BASE_URL}/${APP_ROUTES.DEFAULT_LOGIN}`,
      {
        username,
        password,
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const handleForgotPassword = async (email) => {
  try {
    let res = await axiosInstance.post(
      `${BASE_URL}/${APP_ROUTES.FORGOT_PASSWORD}`,
      {
        username: email,
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

const sample = {
  ssos: [
    {
      name: "Microsoft",
      icon_url: "download1/images/sso_icons/microsoft-01.png",
      login_url: "/api/auth/azure/84/login",
      seq: 1,
    },
  ],
};

export const getSSODetails = async () => {
  try {
    let res = await axiosInstance.get(
      `${BASE_URL}/${APP_ROUTES.GET_SSO_DETAILS}`
    );
    if (res.data) return res.data;
    return sample;
  } catch (error) {
    console.log(error);
  }
};

export const getLogo = async () => {
  try {
    let res = await axiosInstance.get(`${BASE_URL}/${APP_ROUTES.GET_LOGO}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
