export const STATIC_TEXTS = {
  USERNAME_PLACEHOLDER: "Username",
  PASSWORD_PLACEHOLDER: "Password",
  LOGIN_BUTTON_TEXT: "Login",
  FORGOT_PASSWORD_TEXT: "Forgot Password?",
  EMAIL_USERNAME_PLACEHOLDER: "Email/Username",
  SUBMIT_BUTTON_TEXT: "Submit",
  EMAIL_USERNAME_EMPTY_ERROR: "Email/Username cannot be empty",
  EMAIL_INVALID_ERROR: "Email is invalid",
  USERNAME_PASSWORD_EMPTY_ERROR: "Username/Password cannot be empty",
  LOGIN_ERROR: "Invalid username or password",
  INVALID_LOGIN_TEXT: "Invalid Login",
};
