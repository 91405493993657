import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import ErrorPage from "../pages/errorPage/ErrorPage";
import LoginPage from "../pages/loginPage/LoginPage";

export const RouterConfig = () => {
  const tags = useSelector((state) => state.global?.tags);

  useEffect(() => {
    const BASE_URL =
      process.env.REACT_APP_NODE_ENV === "local"
        ? `${process.env.REACT_APP_IMPARTUS_BASE_URL}`
        : ``;
    const favicon = document.getElementById("favicon");
    favicon.href = `${BASE_URL}/assets/images/favicon.ico`;
    if (tags) {
      document.title = tags?.IMPARTUS_LECTURE_CAPTURE;
    }
  }, [tags]);
  return (
    <>
      <Routes>
        <Route path="/lcs/login" element={<LoginPage />} />
        <Route path="/lcs/error" element={<ErrorPage />} />
      </Routes>
    </>
  );
};
