import { Buffer } from "buffer";
import { appServices } from "../../services";
export const GET_DATA_FOR_URI = "GET_DATA_FOR_URI";
export const LOGIN = "LOGIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_ACTIVE_INDEX = "SET_ACTIVE_INDEX";
export const TOGGLE_TOAST = "TOGGLE_TOAST";
export const GET_SSO_DETAILS = "GET_SSO_DETAILS";
export const SET_HOST = "SET_HOST";
export const GET_LOGO = "GET_LOGO";
export const SET_ERROR_CODE = "SET_ERROR_CODE";

export const getDataForURI = (uri) => {
  return async (dispatch) => {
    let res = null;
    try {
      res = await appServices.getDataForURI(uri);

      if (res) {
        //download images
        let BASE_URL =
          process.env.REACT_APP_NODE_ENV === "local"
            ? process.env.REACT_APP_IMPARTUS_BASE_URL
            : "";
        res?.data?.carousels.forEach((item) => {
          localStorage.setItem(
            `BG_IMAGE_${item.seq}`,
            `${BASE_URL}${item.url}`
          );
        });
      }
    } catch (err) {
      console.log(err);
    }
    dispatch({
      type: GET_DATA_FOR_URI,
      payload: res?.data,
    });
  };
};

export const handleDefaultLogin = (username, password) => {
  return async (dispatch) => {
    let res = null;
    try {
      res = await appServices.handleDefaultLogin(username, password);
      let token = res?.data?.token;
      if (res?.data?.success) {
        if (res?.data?.userType !== 7) {
          saveToken(token);
        } else {
          saveAdminToken(token);
        }
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(toggleLoading(false));
    dispatch({
      type: LOGIN,
      payload: res?.data,
    });
  };
};

export const handleForgotPassword = (email) => {
  return async (dispatch) => {
    let res = null;
    try {
      res = await appServices.handleForgotPassword(email);
    } catch (err) {
      console.log(err);
    }
    dispatch(toggleLoading(false));
    let forgetPaswordResponse = null;
    let status = null;
    if (res?.status === 200) {
      forgetPaswordResponse = res?.data?.message;
      status = res?.status;
    }
    dispatch({
      type: FORGOT_PASSWORD,
      payload: { forgetPaswordResponse, status },
    });
  };
};

export const toggleLoading = (loading) => {
  return {
    type: TOGGLE_LOADING,
    payload: loading,
  };
};

export const setActiveIndex = (index) => {
  return {
    type: SET_ACTIVE_INDEX,
    payload: index,
  };
};

export const toggleToast = (toast) => {
  return {
    type: TOGGLE_TOAST,
    payload: toast,
  };
};

export const getSSODetails = () => {
  return async (dispatch) => {
    let res = null;
    try {
      res = await appServices.getSSODetails();

      if (res) {
        //download icons
        res.ssos.forEach((sso) => {
          let img = new Image();
          img.src = `${process.env.REACT_APP_IMPARTUS_BASE_URL}/${sso.icon_url}`;
          //store in localstorage
          localStorage.setItem(`SSO_ICON_${sso.seq}`, img.src);
        });
      }
    } catch (err) {
      console.log(err);
    }
    dispatch({
      type: GET_SSO_DETAILS,
      payload: res,
    });
  };
};

export const setHost = (host) => {
  return {
    type: SET_HOST,
    payload: host,
  };
};

export const getLogo = () => {
  return async (dispatch) => {
    let res = null;
    try {
      res = await appServices.getLogo();
    } catch (err) {
      console.log(err);
    }
    if (!res) return;
    //checking if res.data.url is an object
    if (res?.data?.imageData) {
      const base64 = btoa(
        String.fromCharCode(...new Uint8Array(res?.data?.imageData?.data))
      );
      const base64String = decodeBase64(base64);
      dispatch({
        type: GET_LOGO,
        payload: base64String,
      });
    } else {
      let BASE_URL =
        process.env.REACT_APP_NODE_ENV === "local"
          ? process.env.REACT_APP_IMPARTUS_BASE_URL
          : ``;
      dispatch({
        type: GET_LOGO,
        payload: `${BASE_URL}${res.data.url}`,
      });
    }
  };
};

export const setErrorCode = (code) => {
  return {
    type: SET_ERROR_CODE,
    payload: code,
  };
};

const saveToken = (token) => {
  //remove existing cookie
  document.cookie = "Bearer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //set new cookie
  let expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 180);
  document.cookie = `Bearer=${token}; expires=${expiryDate}; path=/;`;
};

const saveAdminToken = (token) => {
  //remove existing cookie
  document.cookie =
    "adminBearer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //set new cookie
  let expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 180);
  document.cookie = `adminBearer=${token}; expires=${expiryDate}; path=/;`;
};

const decodeBase64 = (base64data) => {
  let base64ToString = Buffer.from(base64data, "base64").toString();
  return base64ToString;
};
