import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CarouselView from "../../components/organism/carouselView/CarouselView";
import LoginComponent from "../../components/organism/loginComponent/LoginComponent";
import {
  getDataForURI,
  getLogo,
  getSSODetails,
} from "../../redux/actions/appActions";
import "./LoginPage.scss";

const LoginPage = () => {
  const dispatch = useDispatch();
  const isDesktop = useSelector((state) => state.global.isDesktop);
  const activeIndex = useSelector((state) => state.app.activeIndex);
  const [showInitialLoader, setShowInitialLoader] = React.useState(
    localStorage.getItem(`BG_IMAGE_${activeIndex + 1}`) ? false : true
  );
  const [currentBGImage, setCurrentBGImage] = React.useState(
    localStorage.getItem(`BG_IMAGE_${activeIndex + 1}`)
  );
  useEffect(() => {
    dispatch(getSSODetails());
    dispatch(getDataForURI("default"));
    dispatch(getLogo());
    // setting timeout for initial loader
    setTimeout(() => {
      setShowInitialLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    let imageSrc = localStorage.getItem(`BG_IMAGE_${activeIndex + 1}`);
    setCurrentBGImage(imageSrc);
  }, [activeIndex]);

  if (showInitialLoader) {
    return (
      <div className="initial-loader-container">
        <div className="progress"></div>
      </div>
    );
  }
  if (!isDesktop) {
    return (
      <div className="login-page-container-mobile">
        <LoginComponent />
      </div>
    );
  }
  return (
    <div className="login-page-container">
      {/* showing loader if currentBGImage is NA */}
      {!currentBGImage ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div
          id="login-page-bg-image"
          style={{
            backgroundImage: `url(${currentBGImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "-1",
            transition: "background-image 1s ease-in-out",
          }}
        ></div>
      )}
      <CarouselView />
      <LoginComponent />
    </div>
  );
};

export default LoginPage;
