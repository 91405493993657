import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CorrectSVG } from "../../atoms/Icons";
import "./ShowForgetPasswordResponse.scss";

const ShowForgotPasswordResponse = (props) => {
  const { setShowForgotPasswordResponse, setShowForgotPassword } = props;
  const forgetPasswordResponse = useSelector(
    (state) => state.app.forgotPassword
  );
  const tags = useSelector((state) => state.global?.tags);

  const [heading, setHeading] = React.useState("");
  const [caption, setCaption] = React.useState("");
  const [status, setStatus] = React.useState("");

  useEffect(() => {
    if (forgetPasswordResponse) {
      if (forgetPasswordResponse.status === 200) {
        setStatus("success");
        setHeading(tags?.WE_SENT_YOUR_PASSWORD);
        setCaption(tags?.PLEASE_CHECK_FOR_EMAIL);
      } else {
        setStatus("error");
        setCaption(tags?.NO_SUCH_USER_EXISTS);
      }
    }
  }, [forgetPasswordResponse]);

  if (status === "success") {
    return (
      <div className="show-forgot-password-response-container">
        <CorrectSVG />
        <div className="show-forgot-password-response-heading">{heading}</div>
        <div className="show-forgot-password-response-caption">{caption}</div>
        <button
          onClick={() => {
            setShowForgotPasswordResponse(false);
            setShowForgotPassword(false);
          }}
          className="show-forgot-password-response-button"
        >
          Login
        </button>
      </div>
    );
  }
  if (status === "error") {
    return (
      <div className="show-forgot-password-response-container">
        <div className="show-forgot-password-response-caption">{caption}</div>
        <button
          onClick={() => {
            setShowForgotPasswordResponse(false);
            setShowForgotPassword(false);
          }}
          className="show-forgot-password-response-button"
        >
          Go Back
        </button>
      </div>
    );
  }
  return (
    <div className="show-forgot-password-response-container">
      <div className="spinner"></div>
    </div>
  );
};

export default ShowForgotPasswordResponse;
