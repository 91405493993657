import React from "react";
import { useSelector } from "react-redux";
import "./CarouselText.scss";

const CarouselText = (props) => {
  const { activeIndex } = props;
  const data = useSelector((state) => state.app?.data);
  return (
    <>
      {data ? (
        <div>
          {/* different text for different indices */}
          <div className="text-container">
            <section className="head">{`${
              data.carousels[activeIndex]?.title
                ? data.carousels[activeIndex]?.title
                : ""
            }`}</section>
            <section className="text">{`${
              data?.carousels[activeIndex]?.content
                ? data?.carousels[activeIndex]?.content
                : ""
            }`}</section>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CarouselText;
