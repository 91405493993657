import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CarouselIndicator.scss";

const CarouselIndicator = (props) => {
  const { activeIndex, setIndex } = props;
  const [firstTime, setFirstTime] = useState(true);
  const carouselLength = useSelector(
    (state) => state.app?.data?.carousels?.length
  );
  const handleOnClick = (index) => {
    setIndex(index);
  };

  // for the first setIndex wait for 2 seconds
  useEffect(() => {
    setTimeout(() => {
      setIndex(0);
      setFirstTime(false);
    }, 2000);
  }, []);

  //automatically change the index after every 7 seconds
  useEffect(() => {
    if (firstTime) {
      return;
    }
    const interval = setInterval(() => {
      if (activeIndex === carouselLength - 1) {
        setIndex(0);
      } else {
        setIndex(activeIndex + 1);
      }
    }, 7000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="carousel-indicator">
      {Array.from(Array(carouselLength).keys()).map((index) => (
        <span
          key={index}
          className={`indicator ${index === activeIndex ? "active" : ""}`}
          onClick={() => handleOnClick(index)}
        ></span>
      ))}
    </div>
  );
};

export default CarouselIndicator;
