import { APP_ROUTES } from "./apiRoutes";
import axiosInstance from "./axiosInstance";

const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "local"
    ? process.env.REACT_APP_IMPARTUS_BASE_URL
    : "";

export const getTags = async () => {
  let res = null;
  try {
    res = await axiosInstance.get(`${BASE_URL}/${APP_ROUTES.GET_TAGS}`, {
      params: {
        lang: "0",
      },
    });
  } catch (err) {
    console.log(err);
  }
  return res?.data;
};
