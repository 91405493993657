import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "./ErrorPage.scss";

const ErrorPage = () => {
  const code = useSelector((state) => state.app?.errorCode);
  const tags = useSelector((state) => state.global?.tags);
  const [message, setMessage] = React.useState("");
  const getMessageForCode = (code) => {
    if (code === "default") {
      setMessage("Something went wrong!");
    } else if (tags?.[code]) {
      setMessage(tags?.[code]);
    } else {
      setMessage("Something went wrong!");
    }
  };
  React.useEffect(() => {
    getMessageForCode(code);
  }, [code, tags]);
  const navigate = useNavigate();
  return (
    <div className="error-page-container">
      <div className="image" />
      <div className="error-message">{message}</div>
      <button
        className="button"
        onClick={() => {
          navigate("/lcs/login");
        }}
      >
        Retry
      </button>
    </div>
  );
};

export default ErrorPage;
