import { globalServices } from "../../services";

export const SET_IS_DESKTOP = "SET_IS_DESKTOP";
export const GET_TAGS = "GET_TAGS";

export const setIsDesktop = (isDesktop) => {
  return {
    type: SET_IS_DESKTOP,
    payload: isDesktop,
  };
};

export const getTags = () => {
  return async (dispatch) => {
    let res = null;
    try {
      res = await globalServices.getTags();
    } catch (err) {
      console.log(err);
    }
    dispatch({
      type: GET_TAGS,
      payload: res,
    });
  };
};
