import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDefaultLogin,
  handleForgotPassword,
  toggleLoading,
  toggleToast,
} from "../../../redux/actions/appActions";
import { redirectAfterLogin } from "../../../utils/utilityFunctions";
import { BackSVG, HidePasswordSVG, ShowPasswordSVG } from "../../atoms/Icons";
import ShowForgotPasswordResponse from "../../molecules/showForgetPasswordResponse/ShowForgetPasswordResponse";
import SsoButton from "../../molecules/ssoButton/SsoButton";
import { STATIC_TEXTS } from "./CONSTANTS";
import "./LoginComponent.scss";

const LoginComponent = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const [showInvalidLogin, setShowInvalidLogin] = React.useState(false);
  const [showBackButton, setShowBackButton] = React.useState(false);
  const dispatch = useDispatch();
  const ssoDetails = useSelector((state) => state.app.ssoDetails);
  const logoURL = useSelector((state) => state.app.logo);
  const loginInfo = useSelector((state) => state.app.loginInfo);
  const isDesktop = useSelector((state) => state.global.isDesktop);
  const tags = useSelector((state) => state.global?.tags);
  const [invalidLoginText, setInvalidLoginText] = React.useState("");
  const [showForgotPasswordResponse, setShowForgotPasswordResponse] =
    React.useState(false);

  const handleForgotPasswordlink = () => {
    // return if email is empty
    if (!email) {
      dispatch(
        toggleToast({
          message: STATIC_TEXTS.EMAIL_USERNAME_EMPTY_ERROR,
          type: "error",
        })
      );
      return;
    }
    dispatch(toggleLoading(true));
    dispatch(handleForgotPassword(email));
    setEmail("");
    setShowBackButton(false);
    setShowForgotPasswordResponse(true);
  };
  const handleLogin = () => {
    // return if username or password is empty
    if (!username || !password) {
      dispatch(
        toggleToast({
          message: STATIC_TEXTS.USERNAME_PASSWORD_EMPTY_ERROR,
          type: "error",
        })
      );
      return;
    }
    dispatch(toggleLoading(true));
    dispatch(handleDefaultLogin(username, password));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  const handleToggle = () => {
    let x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (loginInfo === "NOT_LOGGED_IN") return;
    if (loginInfo?.success) {
      redirectAfterLogin(loginInfo?.userType);
    }
    if (!loginInfo?.success) {
      if (loginInfo?.message) {
        setShowInvalidLogin(true);
        let message = "";
        switch (loginInfo?.message) {
          case "wrong domain accessed":
            message = tags?.NO_SUCH_USER_EXISTS;
            break;
          case "user account is expired":
            message = tags?.YOUR_ACCOUNT_HAS_BEEN_EXPIRED;
            break;
          case "institute account is deactivated":
            message = tags?.INSTITUTE_BLOCKED;
            break;
          case "no-such-user":
            message = tags?.NO_SUCH_USER_EXISTS;
            break;
          default:
            message = tags?.YOUR_ACCOUNT_HAS_BEEN_LOCKED;
            break;
        }
        setInvalidLoginText(message);
        dispatch(
          toggleToast({
            message: message,
            type: "error",
          })
        );
      } else {
        setShowInvalidLogin(true);
        setInvalidLoginText(STATIC_TEXTS.INVALID_LOGIN_TEXT);
        dispatch(
          toggleToast({
            message: STATIC_TEXTS.LOGIN_ERROR,
            type: "error",
          })
        );
      }
    }
  }, [loginInfo]);

  return (
    <div
      style={isDesktop ? { marginBottom: "150px" } : { marginBottom: "0px" }}
      className="login-component-container"
    >
      {logoURL ? (
        <div className="login-component-logo">
          <img src={logoURL} alt="" className="login-component-logo-image" />
        </div>
      ) : (
        <div style={{ height: "50px" }}></div>
      )}
      {showBackButton && (
        <div className="login-component-back-button">
          <button
            className="button-style"
            onClick={() => {
              setShowForgotPassword(false);
              setShowBackButton(false);
            }}
          >
            <BackSVG />
          </button>
        </div>
      )}
      <div className="login-component-form">
        {ssoDetails?.ssos && !showForgotPassword ? (
          <>
            <SsoButton />
            <div className="horizontal-separator" />
          </>
        ) : (
          <></>
        )}
        {!showForgotPassword ? (
          <>
            <div className="login-component-form-input">
              <input
                className="input-field"
                type="text"
                value={username}
                placeholder={STATIC_TEXTS.USERNAME_PLACEHOLDER}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setShowInvalidLogin(false);
                }}
              />
            </div>
            <div className="login-component-form-input">
              <input
                className="input-field"
                id="password"
                type="password"
                value={password}
                placeholder={STATIC_TEXTS.PASSWORD_PLACEHOLDER}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setShowInvalidLogin(false);
                }}
                autoComplete="current-password"
                onKeyPress={(e) => handleKeyPress(e)}
              />
              <span
                className="password-toggle-icon"
                onClick={() => handleToggle()}
              >
                {!showPassword ? <ShowPasswordSVG /> : <HidePasswordSVG />}
              </span>
            </div>
            <div className="login-component-form-button">
              <button className="button-style" onClick={() => handleLogin()}>
                {STATIC_TEXTS.LOGIN_BUTTON_TEXT}
              </button>
            </div>
            {showInvalidLogin && (
              <div className="login-component-form-invalid-login">
                {invalidLoginText}
              </div>
            )}
            {/* forget password */}
            <div className="login-component-form-forget-password">
              <a
                className="forget-password"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowForgotPassword(true);
                  setShowBackButton(true);
                }}
              >
                {STATIC_TEXTS.FORGOT_PASSWORD_TEXT}
              </a>
            </div>
          </>
        ) : showForgotPasswordResponse ? (
          <ShowForgotPasswordResponse
            setShowForgotPasswordResponse={setShowForgotPasswordResponse}
            setShowForgotPassword={setShowForgotPassword}
          />
        ) : (
          <>
            <div className="login-component-form-input">
              <input
                className="input-field"
                type="text"
                value={email}
                placeholder={STATIC_TEXTS.EMAIL_USERNAME_PLACEHOLDER}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleForgotPasswordlink();
                  }
                }}
              />
            </div>
            <div className="login-component-form-button">
              <button
                onClick={() => handleForgotPasswordlink()}
                className="button-style"
              >
                {STATIC_TEXTS.SUBMIT_BUTTON_TEXT}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginComponent;
