export const ShowPasswordSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="20"
    height="20"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <path
        fillRule="evenodd"
        d="M153.349 255.994c0-56.578 46.02-102.617 102.659-102.617 56.579 0 102.598 46.038 102.598 102.617 0 56.591-46.019 102.61-102.598 102.61-56.64 0-102.659-46.019-102.659-102.61zm-124.815-9.79c-3.54 6.403-3.54 13.177 0 19.586 45.959 82.53 133.115 133.799 227.474 133.799 94.297 0 181.454-51.268 227.413-133.799 3.601-6.409 3.601-13.183 0-19.586-45.959-82.53-133.115-133.793-227.413-133.793-94.359 0-181.515 51.263-227.474 133.793zm227.474-59.197c-38.085 0-69.03 30.944-69.03 68.987 0 38.042 30.944 68.987 69.03 68.987 38.024 0 68.968-30.944 68.968-68.987s-30.944-68.987-68.968-68.987z"
        clipRule="evenodd"
        fill="#a6a9a9"
        opacity="1"
        dataoriginal="#000000"
      />
    </g>
  </svg>
);

export const HidePasswordSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="20"
    height="20"
    x="0"
    y="0"
    viewBox="0 0 128 128"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="m79.891 65.078 7.27-7.27C87.69 59.787 88 61.856 88 64c0 13.234-10.766 24-24 24-2.144 0-4.213-.31-6.192-.839l7.27-7.27a15.929 15.929 0 0 0 14.813-14.813zm47.605-3.021c-.492-.885-7.47-13.112-21.11-23.474l-5.821 5.821c9.946 7.313 16.248 15.842 18.729 19.602C114.553 71.225 95.955 96 64 96c-4.792 0-9.248-.613-13.441-1.591l-6.573 6.573C50.029 102.835 56.671 104 64 104c41.873 0 62.633-36.504 63.496-38.057a3.997 3.997 0 0 0 0-3.886zm-16.668-39.229-88 88C22.047 111.609 21.023 112 20 112s-2.047-.391-2.828-1.172a3.997 3.997 0 0 1 0-5.656l11.196-11.196C10.268 83.049 1.071 66.964.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24c10.827 0 20.205 2.47 28.222 6.122l12.95-12.95c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656zM34.333 88.011 44.46 77.884C41.663 73.96 40 69.175 40 64c0-13.234 10.766-24 24-24 5.175 0 9.96 1.663 13.884 4.459l8.189-8.189C79.603 33.679 72.251 32 64 32 32.045 32 13.447 56.775 8.707 63.994c3.01 4.562 11.662 16.11 25.626 24.017zm15.934-15.935 21.809-21.809C69.697 48.862 66.958 48 64 48c-8.822 0-16 7.178-16 16 0 2.958.862 5.697 2.267 8.076z"
        fill="#a6a9a9"
        opacity="1"
        dataoriginal="#000000"
        class=""
      />
    </g>
  </svg>
);

export const BackSVG = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 219.151 219.151"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575
		C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575
		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"
      ></path>
      <path
        d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
		c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"
      ></path>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const CorrectSVG = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_897_438)">
      <path
        d="M36 72C55.8822 72 72 55.8822 72 36C72 16.1177 55.8822 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8822 16.1177 72 36 72Z"
        fill="#00BA00"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.7589 53.5573L11.5926 39.4654C9.79001 37.5361 9.89347 34.4838 11.8227 32.6812C13.752 30.8787 16.8043 30.9824 18.6069 32.9114L28.7629 43.7812L44.8384 28.7612C44.983 28.626 45.1341 28.5021 45.2903 28.3882L52.85 21.3251C54.7792 19.5225 57.8319 19.6263 59.6341 21.5555C61.4367 23.4845 61.3329 26.5372 59.404 28.3397L36.3146 49.9128L36.29 49.8864L28.3031 57.3489L24.7589 53.5573Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_897_438">
        <rect width="72" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
