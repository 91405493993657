export const redirectAfterLogin = (userType) => {
  let attemptedHash = localStorage.getItem("attemptedHashBeforeLogin") || "";
  if (attemptedHash) {
    localStorage.removeItem("attemptedHashBeforeLogin");
    let url = window.location.origin + attemptedHash;
    window.location.replace(url);
  }
  if (userType !== 7) {
    let url = window.location.origin + "/ilc";

    window.location.replace(url);
  } else {
    let url = window.location.origin + "/admin";

    window.location.replace(url);
  }
};

