import { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import "./App.scss";
import { RouterConfig } from "./Routes/RouterConfig";
import CustomToast from "./components/atoms/customToast/CustomToast";
import { setErrorCode, setHost } from "./redux/actions/appActions";
import { getTags, setIsDesktop } from "./redux/actions/globalActions";
import { store } from "./redux/store";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      setLoading(store.getState().app.loading);
    });
  }, []);
  return (
    <div className="App">
      {/* loader */}
      {loading && (
        <div className="loader-overlay">
          <div className="dots"></div>
        </div>
      )}
      <Provider store={store}>
        <Pages />
      </Provider>
    </div>
  );
}

function Pages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryParams = Object.fromEntries(params.entries());
  useEffect(() => {
    const host = window.location.host;
    dispatch(setHost(host));
    dispatch(getTags());

    // route to error page if query params are present

    let { error } = queryParams;
    if (error) {
      dispatch(setErrorCode(error));
      navigate("/lcs/error");
    }

    //redirect if the cookie is present
    const cookie = document.cookie;
    if (cookie.includes("adminBearer") && !cookie.includes(undefined)) {
      // navigate("/admin");
      window.location.href = "/admin";
    } else if (cookie.includes("Bearer") && !cookie.includes(undefined)) {
      // navigate("/ilc");
      window.location.href = "/ilc";
    }
  }, []);

  useEffect(() => {
    //dynamically set isDesktop based on window width
    const setResponsiveness = () => {
      return window.innerWidth > 1000
        ? dispatch(setIsDesktop(true))
        : dispatch(setIsDesktop(false));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  return (
    <>
      <CustomToast />
      <RouterConfig />
    </>
  );
}

export default App;
