import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import { rootReducer } from "./reducers/rootReducer";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};
// devtools for debugging in dev environment.
const devTools =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a) => a;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk), devTools)
);
const persistor = persistStore(store);

export { persistor, store };
