import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CustomToast.scss";

const CustomToast = () => {
  const [show, setShow] = useState(false);
  const toast = useSelector((state) => state.app.toast);
  const { message, type } = toast;
  useEffect(() => {
    if (!(message && type)) {
      return;
    }
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [toast]);

  return (
    <div className={`toast-container ${show ? "show" : ""}`}>
      <div className={`toast ${type}`}>
        <div className="toast-message">{message}</div>
      </div>
    </div>
  );
};

export default CustomToast;
