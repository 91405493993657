import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveIndex } from "../../../redux/actions/appActions";
import CarouselIndicator from "../../molecules/carouselIndicator/CarouselIndicator";
import CarouselText from "../../molecules/carouselText/CarouselText";
import "./CarouselView.scss";

const CarouselView = () => {
  const [index, setIndex] = React.useState(2);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveIndex(index));
  }, [index]);

  return (
    <div className="carousel-view-container">
      <CarouselText activeIndex={index} />
      <CarouselIndicator activeIndex={index} setIndex={setIndex} />
    </div>
  );
};

export default CarouselView;
