import React from "react";
import { useSelector } from "react-redux";
import "./SsoButton.scss";

const IconForUrl = (url, host) => {
  let iconUrl =
    process.env.REACT_APP_NODE_ENV === "local"
      ? `${process.env.REACT_APP_IMPARTUS_BASE_URL}/${url}`
      : `/${url}`;
  return (
    <img
      src={iconUrl}
      alt=""
      style={{
        width: "20px",
        height: "20px",
        marginRight: "10px",
      }}
    />
  );
};

const SsoButton = () => {
  const ssoDetails = useSelector((state) => state.app.ssoDetails);
  const host = useSelector((state) => state.app.host);
  const handleLogin = async (url) => {
    //open in same tab
    const SSO_LOGIN_URL =
      process.env.REACT_APP_NODE_ENV === "local"
        ? `${process.env.REACT_APP_IMPARTUS_BASE_URL}${url}`
        : `${url}`;
    window.location.href = `${SSO_LOGIN_URL}`;
  };
  return (
    <>
      {ssoDetails?.ssos.map((sso, index) => (
        <div className="sso-button-container" key={index}>
          <button
            onClick={() => handleLogin(sso.login_url)}
            className="sso-button"
          >
            {`Login with ${sso.name}`}
            {IconForUrl(sso.icon_url, host)}
          </button>
        </div>
      ))}
    </>
  );
};

export default SsoButton;
