import {
  FORGOT_PASSWORD,
  GET_DATA_FOR_URI,
  GET_LOGO,
  GET_SSO_DETAILS,
  LOGIN,
  SET_ACTIVE_INDEX,
  SET_ERROR_CODE,
  SET_HOST,
  TOGGLE_LOADING,
  TOGGLE_TOAST,
} from "../actions/appActions";

const initialState = {
  loading: false,
  data: null,
  activeIndex: 0,
  ssoDetails: null,
  toast: {
    message: "",
    type: "",
  },
  host: "",
  loginInfo: "NOT_LOGGED_IN",
  errorCode: "default",
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loginInfo: action.payload,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_DATA_FOR_URI:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload,
      };
    case TOGGLE_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case GET_SSO_DETAILS:
      return {
        ...state,
        ssoDetails: action.payload,
      };
    case SET_HOST:
      return {
        ...state,
        host: action.payload,
      };
    case GET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
      };
    default:
      return state;
  }
};
